import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ACTION_LOADING } from '../../../component/store/actions/actions';
//Interfaces
import { ColumnsForCollectionsManagement, IAccountancyCards, IAccountancyDays, Pagination, TmpAccotingConfig, UnitsAccountingFilters } from '../../../interface/accountancy.interface';
//Services
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { AmplitudeEvent } from 'src/app/interface/amplitude.interface';
import { UnitStatus } from 'src/app/interface/units.interface';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { DataService } from 'src/app/services/data.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-payment-managment',
  templateUrl: './payment-managment.component.html',
  styleUrls: ['./payment-managment.component.scss']
})
export class PaymentManagmentComponent implements OnInit {
  @Input('statusConfig') statusConfig:TmpAccotingConfig;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: ColumnsForCollectionsManagement[] = [ColumnsForCollectionsManagement.select, ColumnsForCollectionsManagement.unit, ColumnsForCollectionsManagement.expiredDays, ColumnsForCollectionsManagement.expiredDebt, ColumnsForCollectionsManagement.debtToBeDue, ColumnsForCollectionsManagement.totalDebt, ColumnsForCollectionsManagement.actions];
  @ViewChild('compatibility', { static: true }) compatibilityContent: ElementRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //Modal
  modalRef: any;
  typeModal: string = "reminder";
  compatibilityModal: number = 0;
  //Main Data
  debts: any[] = [];
  accountancyCards: any;
  selected: any[] = [];
  countsSelected: number = 0;
  sortCardSelected: string = 'all';
  buildingId: string;
  unitId: string;
  dateFilter: any;
  //State management
  checkGray: string = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-gray.png';
  checkYellow: string = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-yellow.png';
  buttonSelect: string = 'onboarding.transactions.selectAll';
  selectState: boolean = false;
  imageGeneral: boolean = false;
  stateSelectAllRow: boolean = false;
  tempStyleCard: string;
  isVisibleDataTable: boolean = true;
  pagination: Pagination = {
    page: 1,
    size: 10
  };
  accountancy = IAccountancyDays;
  accountancyOptionsFilter: { name: string, filter: string }[];
  @Input() units: any[] = [];
  @Output() emitSelectedUnit = new EventEmitter<any>();
  payUnitNewVersion: any[] = [];
  selectOption: string;
  receivable: number = 0;

  constructor(private modalService: NgbModal,
    private onboardingService: OnboardingService,
    private translate: TranslateService,
    private dataService: DataService,
    private userService: UserService,
    private toastr: ToastrService,
    private amplitudeService: AmplitudeService) {
    this.buildingId = this.userService.getBuilding().id;
    this.accountancyOptionsFilter = Object.keys(this.accountancy).map((data) => ({ name: this.accountancy[data].name, filter: this.accountancy[data].filter }));
  }

  ngOnInit(): void {
    this.getDebts();
    this.getCard();
    setTimeout(() => {
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
    }, 500);

  }

  getDebts() {
    this.isVisibleDataTable = true;
    let { ...query }: any = this.pagination;
    if (this.unitId) query['unitId'] = this.unitId;
    this.debts.length = 0;
    this.pagination.totalDocs = 0;
    this.pagination.totalPages = 0;
    if (this.sortCardSelected != 'all') query.expirationDays = this.sortCardSelected;

    query['status'] = UnitStatus.active;

    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.getUnitsAccountancyByBuilding(this.buildingId, query).subscribe((resp: any) => {
      if (resp.data.docs.length == 0) {
        this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
        return this.isVisibleDataTable = false
      };

      this.debts = resp.data.docs;

      for (let d of this.debts) {
        if (!d.src) d.src = false;
        for (let i = 0; i < this.selected.length; i++) {
          if (d.id === this.selected[i].id) {
            d.src = true;
            break;
          }
        }
        d.status = this.chooseMarker(this.sortCardSelected, d.accountancy.daysOwed);
      }

      this.dataSource.data.length = 0;
      this.dataSource = new MatTableDataSource(this.debts);
      this.pagination.totalDocs = resp.data.totalDocs;
      this.pagination.totalPages = resp.data.totalPages;
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    }, error => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
      this.isVisibleDataTable = true;
    });
  }

  getAllUnitsOfACategory() {
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.getUnitsIdByBuilding(this.buildingId, { expirationDays: this.sortCardSelected }).subscribe((resp: any) => {
      this.selected.push(...resp.data);
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    }, error => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });
  }

  getCard() {
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    this.onboardingService.getAccountStatementByCards().subscribe((resp: IAccountancyCards) => {

      this.accountancyCards = resp.data;

      this.receivable = (resp.data.expired1To30Days.amount + resp.data.expired30To60Days.amount + resp.data.expired60Days.amount);

      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    }, error => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
    });

  }


  selectAllItems() {

    if (!this.stateSelectAllRow) {    //Select
      this.countsSelected = this.pagination.totalDocs;
      this.selectState = true;
      this.getAllUnitsOfACategory();
      //Then change check on all 
      this.imageGeneral = true;
      this.stateSelectAllRow = true;
      this.buttonSelect = this.translate.instant('onboarding.transactions.deselectAll');
      this.debts.forEach((transaction: any) => transaction.src = true);
    } else {
      //Pull out all debts on array
      this.countsSelected = 0;
      this.selectState = false;
      this.stateSelectAllRow = false;
      //Then change check on all
      this.imageGeneral = false;
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
      this.debts.forEach((transaction: any) => transaction.src = false);
      this.selected.length = 0;
    }

  }

  selectUnit(transaction: any, id: string) {
    transaction.src = transaction.src = !transaction.src
    this.changeSelected(transaction);
  }

  changeSelected(debt: any) {
    let isSelected = false;
    let indexFoundIt = null;

    for (let i = 0; i < this.selected.length; i++) {
      if (debt.id == this.selected[i].id) {
        isSelected = true;
        indexFoundIt = i;
        break;
      }
    }

    if (isSelected) {
      this.countsSelected = this.countsSelected - 1;
      this.selected.splice(indexFoundIt, 1);
    } else {

      this.countsSelected = this.countsSelected + 1;
      this.selected.push(debt);
    }

    // We change the functionality of the button according to the selected elements
    if (this.selected.length > 0) {
      this.buttonSelect = this.translate.instant('onboarding.transactions.deselectAll');
      this.imageGeneral = true;
      this.stateSelectAllRow = true;
      this.selectState = false;
    } else {
      this.buttonSelect = this.translate.instant('onboarding.transactions.selectAll');
      this.imageGeneral = false;
      this.stateSelectAllRow = false;
      this.selectState = false;
    }

  }

  isSelectRowTable(id: string) {
    const isSelect = this.selected.some(management => management.id === id);
    return isSelect;
  }

  selectDebt(debt: any, id: string) {
    let image = document.getElementById(id) as HTMLImageElement;
    let nameCheck = image.src.split('/').pop();
    if (nameCheck == 'check-gray.png') {
      image.src = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-yellow.png';
    } else {
      image.src = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-gray.png';
      // If an item is deselected, the selectAll is changed
      let selectAll = document.getElementById('selectAll') as HTMLImageElement;
      selectAll.src = 'https://munily-public-cdn.s3.amazonaws.com/general/icons/check-gray.png';
    }

    this.changeSelected(debt)
  }

  selectCard(sort: string, typeSort?: string) {
    if (this.dataSource.data.length > 0) this.paginator.firstPage();

    if (typeSort !== 'selectSort') this.selectOption = null;
    if (sort == this.sortCardSelected || sort == this.tempStyleCard) {
      this.sortCardSelected = 'all';
      this.tempStyleCard = '';
    } else {
      this.sortCardSelected = sort;
    }

    this.pagination.page = 1;
    this.getDebts();
  }

  chooseMarker(status: string, days: number) {

    switch (true) {
      case (days <= 0):
        return 'green'
        break;
      case (days > 1 && days <= 30):
        return 'orange'
        break;
      case (days > 31 && days <= 60):
        return 'yellow'
        break;
      case (days > 61):
        return 'red'
        break;
    }
  }

  closeModal(e: any) {
    if (e.type == "reminder" && e.state) {
      if (this.payUnitNewVersion && this.payUnitNewVersion.length === 1) {
        return this.paySelectUnit(this.payUnitNewVersion);
      }

      this.setDefaultConfigurationsModal();

      const unitsId = this.selected.map(Element => Element.id);

      this.onboardingService.sendNotificacionCollectionsManagement(unitsId).subscribe((resp: any) => {
        this.showMessageSuccessSendNotifications();
        this.selectAllItems();
      });
      this.executedEventAmplitude();
      return;
    }
    this.typeModal = "reminder";
    this.modalRef.close();
  }

  pay() {
    const currentDate = moment().utc();
    let accountancy: any;
    const isAccountancy = this.selected.every((element: any) => {
      accountancy = element.accountancy;
      if (moment(accountancy.invoiceDebtFrom) < currentDate && accountancy.totalDebt > 0 && accountancy.invoiceDebtFrom != null) {
        return true
      }
    });

    if (!isAccountancy) return this.toastr.error(this.translate.instant('onboarding.error.expiredDebt'));
    this.modalRef = this.modalService.open(this.compatibilityContent, { windowClass: "compatibility", backdropClass: "blur-backdrop", backdrop: 'static', keyboard: false, centered: true, size: 'md' });
  }

  validationsPaySelectUnit(unit: any) {
    if (unit.accountancy.totalDebt <= 0 && unit.accountancy.invoiceDebtFrom === null) {
      this.payUnitNewVersion.length = 0;
      return this.toastr.error(this.translate.instant('onboarding.error.expiredDebt'));
    }
    this.payUnitNewVersion = new Array(unit.id);
    this.modalRef = this.modalService.open(this.compatibilityContent, { windowClass: "compatibility", backdropClass: "blur-backdrop", backdrop: 'static', keyboard: false, centered: true, size: 'md' });
  }

  paySelectUnit(unitsId: any[]) {
    this.setDefaultConfigurationsModal();
    this.payUnitNewVersion.length = 0

    this.onboardingService.sendNotificacionCollectionsManagement(unitsId).subscribe((resp: any) => {
      this.showMessageSuccessSendNotifications();
    }, error => {

    });
    this.executedEventAmplitude();
  }

  sortCollections(e: any) {
    if (e.active == ColumnsForCollectionsManagement.status) return;

    if (e.direction !== '') {
      this.pagination.sortField = e.active;
      this.pagination.sortOrder = e.direction == 'asc' ? 1 : -1;
    } else {
      delete this.pagination.sortField;
      delete this.pagination.sortOrder;
    }
    this.getDebts();
  }

  onPageEvent(event) {
    this.pagination.page = event.pageIndex + 1;
    this.pagination.size = event.pageSize;
    this.getDebts();
  }

  exportCollectionsManagement() {
    this.onboardingService.isLoading({ action: ACTION_LOADING, payload: true });
    let query: UnitsAccountingFilters = {};
    query['selectedAll'] = this.selectState;

    if (this.unitId) query['unitId'] = this.unitId;
    if (this.selectState || this.selected.length > 0) {
      query['unitsSelect'] = [];
      for (let sel of this.selected) {
        query['unitsSelect'].push(sel.id);
      }
    }
    if (this.sortCardSelected) {
      query['intervalStatus'] = this.sortCardSelected;
    }

    let building = this.dataService.getBuilding();
    this.onboardingService.exportCollectionsManagement(building.id, query).subscribe((resp: any) => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
      saveAs(resp.data.url, "CollectionsManagement.pdf");
    }, (error: any) => {
      this.onboardingService.isLoading({ action: ACTION_LOADING, payload: false });
      this.toastr.error(error.error.message);
    });
  }

  setAccountancyDays(days: number) {

    if (days === 0) return this.accountancy.upToDate

    const translateDays = this.translate.instant('headersTable.days')
    if (days >= 1 && days <= 30) return { ...this.accountancy.expired1To30Days, name: `${this.accountancy.expired1To30Days.name} ${translateDays}` };
    if (days >= 31 && days <= 60) return { ...this.accountancy.expired31To60Days, name: `${this.accountancy.expired31To60Days.name} ${translateDays}` };

    return { ...this.accountancy.expired60Days, name: `${this.accountancy.expired60Days.name} ${translateDays}` };
  }

  emitNgSelectUnit(unit: any) {
    this.emitSelectedUnit.emit(unit && unit._id ? unit._id : null);
  }

  showMessageSuccessSendNotifications() {
    this.modalRef = this.modalService.open(this.compatibilityContent, { windowClass: "compatibility", backdropClass: "blur-backdrop", backdrop: 'static', keyboard: false, centered: true, size: 'md' });
  }

  executedEventAmplitude() {
    this.amplitudeService.sendToAmplitude({
      event_type: AmplitudeEvent.paymentRequired,
      user_id: this.userService.getUser().id,
      event_properties: {
        'Users Up to Day': `${this.accountancyCards.unexpired.percentage}%`,
        'Debtors 1 To 30 Days': `${this.accountancyCards.expired1To30Days.percentage}%`,
        'Debtors 30 To 60 Days': `${this.accountancyCards.expired30To60Days.percentage}%`,
        'Debtors 60 Days': `${this.accountancyCards.expired60Days.percentage}%`,
      },
    });
  }

  setDefaultConfigurationsModal() {
    this.modalRef.close();
    this.typeModal = "compatibility";
    this.compatibilityModal = 6;
  }
}
